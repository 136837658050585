import React from "react";

export default class Footer extends React.Component{
    constructor(){
        super();
        this.state = {
            end_page_list:[{
				title: 'Về Chúng Tôi',
				href : '/Help/?key=1',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('jbo_QRP','Footer','footer','event');
				}
			},{
				title: 'Điều Khoản Điều Kiện',
				href : '/Help/?key=2',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('jbo_QRP','Footer','footer','event');
				}
			},{
				title: 'Thông Tin và Luật Cược',
				href : '/Help/?key=3',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('jbo_QRP','Footer','footer','event');
				}
			},{
				title: 'Liên Lạc',
				href : '/Help/?key=4',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('jbo_QRP','Footer','footer','event');
				}
			},{
				title: 'Đại Lý',
				href : '',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('jbo_QRP','Footer','footer','event');
				}
			},{
				title : 'Tải Ứng Dụng',
				href : '/Download',
				_qaq : ()=>{
					global.globalGtag && global.globalGtag('jbo_QRP','Footer','footer','event');
				}
			}]
        }
    }


    render(){
        let { end_page_list } = this.state;
        const {addAffiliateParamToUrl,mainSiteUrl} = this.props;
        return(
            <div className="Footer-Modal">
                <div className="Footer-Nav-list">
                    <div className="licenseContainer">
                        <span>Thương Hiệu Tài Trợ</span>
                        <img src="/static/images/m3-001/license-1-new.webp" alt="Trang nhà cái cá cược bóng đá, eSports, Casino trực tuyến, trò chơi hàng đầu Châu Á Tham gia chơi cược với những ưu đãi khủng mỗi ngày" />
                    </div>

                    <div className="licenseContainer">
                        <span>Nhà Cung Cấp Trò Chơi</span>
                        <img src="/static/images/m3-001/footer_5.png" alt="Trang nhà cái cá cược bóng đá, eSports, Casino trực tuyến, trò chơi hàng đầu Châu Á Tham gia chơi cược với những ưu đãi khủng mỗi ngày" />
                    </div>

                    <div className="licenseContainer">
                        <span>Phương Thức Thanh Toán</span>
                        <img src="/static/images/m3-001/new_footer_icon.webp" alt="Trang nhà cái cá cược bóng đá, eSports, Casino trực tuyến, trò chơi hàng đầu Châu Á Tham gia chơi cược với những ưu đãi khủng mỗi ngày" />
                    </div>
                </div>


                <div className="footer-end_list">
                    <div className="footer-end_list-box">
                        {/* {
                            end_page_list.map((val, index)=>{
                                return (
                                    <a key={index} target="_blank" onClick={()=>{val._qaq()}} href={this.props.mainSiteUrl+val.href} className="item">{val.title}</a>
                                )
                            })
                        } */}

                         {
                            end_page_list.map((val, index)=>{
                                return (
                                    <a key={index} target="_blank" onClick={()=>{val._qaq()}} href={addAffiliateParamToUrl(mainSiteUrl+val.href)} className="item">{val.title}</a>
                                )
                            })
                        }
                    </div>
                    <span>Copyright © 2011-2023 JBO All Rights Reserved.</span>
                </div>


            </div>
        )
    }
}