/*
 * @Date: 2023-04-25 15:15:31
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 15:17:35
 * @FilePath: \J1-M3-QRP-CODE\config\trace\affDomains.js
 */

//J1M3 沒有指定affcode 有需要再加入
const affDomains = {
    //"localhost" : 'J106542',
}

export default affDomains;
