/*
 * @Date: 2023-03-15 18:06:15
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-05 14:06:16
 * @FilePath: \J1-M3-QRP-CODE\config\trace\traceConfigs.js
 */
/***
 * 這是例子 自行修改
 * 沒有配置的 就填 false
 *
 * const traceConfigs = {
 *   'm2-001': {
 *     piwik: 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
 *     "51la": 21337697,
 *     ga: 'UA-198324647-1',
 *   }
 * }
 *
 */

const traceConfigs = {
    'm3-001': {
        piwik: 'edece658-e298-4c9b-990e-67c1a1438724',
        "51la": false,
        ga: 'UA-130040187-1',
    },
    'm3-002' : {
        piwik: 'edece658-e298-4c9b-990e-67c1a1438724',
        "51la": false,
        ga: 'UA-130040187-1',
    },
    'm3-003' : {
        piwik: 'edece658-e298-4c9b-990e-67c1a1438724',
        "51la": false,
        ga: 'UA-130040187-1',
    },
    'm3-004' : {
        piwik: 'edece658-e298-4c9b-990e-67c1a1438724',
        "51la": false,
        ga: 'UA-130040187-1',
    },
    'm3-005' : {
        piwik: 'edece658-e298-4c9b-990e-67c1a1438724',
        "51la": false,
        ga: 'UA-130040187-1',
    },
}








export default traceConfigs;
