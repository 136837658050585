/*
 * @Date: 2023-02-12 13:09:13
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-13 11:30:42
 * @FilePath: \J1-M3-QRP-CODE\actions\API.js
 */
import HostConfig from '$CONFIG/Host.config'
export const CMSURL = HostConfig.Config.CMSURL;
export const HostApi = HostConfig.Config.HostApi;
export const QRCodeURl = HostConfig.Config.QRCODEURL ;
export const BFFSC_URL =  HostConfig.Config.BFFSC_URL ;

export const ApiPort = {
	// banner 图
	Banner1 : CMSURL + `/cms/qrp-m3-001-banners`,
	Banner2 : CMSURL + `/cms/qrp-m3-002-banners`,
	Banner3 : CMSURL + `/cms/qrp-m3-003-banners`,
	Banner4 : CMSURL + `/cms/qrp-m3-004-banners`,
	Banner5 : CMSURL + `/cms/qrp-m3-005-banners`,

	// 注册API
	Register: HostApi + `/api/QRP/Register`,
	// 登录
	Login : HostApi + `/api/QRP/Login` ,
	// 
	QrpLoad : HostApi + '/api/QRP/URLs' ,

	// APP 下载 二维码
	Qrcode : QRCodeURl + '/thirdparty-payment/api/v1.0/qr/qr-code',

	AffiliateLink : HostApi + `/api/QRP/AffiliateLink` ,

	// Telegram API endpoint
	 Telegram: CMSURL + `/cms/fe-config-m3-cs-telegram`,
	 CaptchaInfo : BFFSC_URL + '/api/Verification/Captcha/Info',
	CaptchaChallenge: BFFSC_URL + '/api/Verification/Captcha/ChallengeId'

}
